<template>
    <div
        class="bg"
        :style="{
            backgroundImage: 'url(http://oss.imttech.cn/case/zhuce_bg2x%20.jpg)'
        }"
    >
        <div class="Register">
            <el-card shadow="never" center>
                <div class="title" slot="header">{{ $t('tian-xie-xin-xi') }}</div>

                <el-form
                    style="width: 550px; margin: auto"
                    :model="form"
                    ref="form"
                    :rules="rules"
                    label-width="150px"
                    label-position="right"
                >
                    <el-form-item :label="$t('shou-ji-hao')" prop="phone">
                        <Phone v-model="form.phone" />
                    </el-form-item>
                    <el-form-item :label="$t('shou-ji-yan-zheng-ma')" prop="code" class="code">
                        <div class="codeInput input2">
                            <el-input
                                v-model="form.code"
                                clearable
                                :placeholder="$t('shu-ru-yan-zheng-ma')"
                                maxlength="6"
                            >
                            </el-input>
                            <el-button type="warning" plain @click="sendMsg" :disabled="isSend">
                                {{ isSend ? `${$t('yi-fa-song')}(${senNum}S)` : $t('huo-qu-yan-zheng-ma') }}</el-button
                            >
                        </div>
                    </el-form-item>
                    <el-form-item :label="$t('she-zhi-mi-ma')" prop="password">
                        <el-input
                            class="input2"
                            type="password"
                            v-model="form.password"
                            :placeholder="
                                isChinese
                                    ? $t(
                                          '816-ge-zi-fu-jian-yi-shi-yong-liang-zhong-huo-liang-zhong-yi-shang-zi-fu-zu-he'
                                      )
                                    : $t('qing-shu-ru')
                            "
                        ></el-input>

                        <div v-if="!isChinese" class="tips">
                            {{ $t('816-ge-zi-fu-jian-yi-shi-yong-liang-zhong-huo-liang-zhong-yi-shang-zi-fu-zu-he') }}
                        </div>
                    </el-form-item>
                    <el-form-item :label="$t('zai-ci-shu-ru-mi-ma')" prop="password2">
                        <el-input
                            class="input2"
                            type="password"
                            v-model="form.password2"
                            :placeholder="
                                isChinese
                                    ? $t(
                                          '816-ge-zi-fu-jian-yi-shi-yong-liang-zhong-huo-liang-zhong-yi-shang-zi-fu-zu-he'
                                      )
                                    : $t('qing-shu-ru')
                            "
                        ></el-input>
                        <div v-if="!isChinese" class="tips">
                            {{ $t('816-ge-zi-fu-jian-yi-shi-yong-liang-zhong-huo-liang-zhong-yi-shang-zi-fu-zu-he') }}
                        </div>
                    </el-form-item>
                    <el-form-item :label="$t('gong-si-ji-gou-yuan-xiao-ming-cheng')" prop="chCompanyName">
                        <el-input
                            class="input2"
                            v-model="form.chCompanyName"
                            :placeholder="$t('qing-shu-ru')"
                        ></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('shang-chuan-ming-pian-zheng-jian')" prop="card">
                        <!-- <crop-upload
                            v-model="form.card"
                            :width="300"
                            :height="300"
                            :ratio="[16, 9]"
                            :imgWidth="220"
                            @input="getImgInfo"
                        >
                            <div slot="tips">
                                请上传清晰证件图片，系统会根据图片计算自动填充下方需填数据
                            </div>
                        </crop-upload> -->

                        <single-upload
                            v-model="form.card"
                            :width="300"
                            :height="300"
                            :ratio="[16, 9]"
                            :imgWidth="220"
                            @input="getImgInfo"
                        >
                            <div slot="tips">
                                {{
                                    $t(
                                        'qing-shang-chuan-qing-xi-zheng-jian-tu-pian-xi-tong-hui-gen-ju-tu-pian-ji-suan-zi-dong-tian-chong-xia-fang-xu-tian-shu-ju'
                                    )
                                }}
                            </div>
                        </single-upload>
                    </el-form-item>
                    <el-form-item :label="$t('xing-ming')" prop="nickname">
                        <el-input
                            class="input1"
                            v-model="form.nickname"
                            style="width: 240px"
                            :placeholder="$t('qing-shu-ru-lian-xi-ren-xing-ming')"
                        ></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('cheng-hu')" prop="sex">
                        <el-radio-group v-model="form.sex">
                            <el-radio :key="index" :label="item[0]" v-for="(item, index) in sexModels">{{
                                item[1].label
                            }}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item :label="$t('you-xiang')" prop="email">
                        <el-input
                            class="input2"
                            v-model="form.email"
                            :placeholder="$t('qing-shu-ru-nin-de-you-xiang')"
                        ></el-input>
                    </el-form-item>
                    
                    <el-form-item :label="$t('zhi-wei')" prop="position">
                        <el-input
                            class="input1"
                            v-model="form.position"
                            style="width: 240px"
                            :placeholder="$t('qing-shu-ru-nin-de-zhi-wei')"
                        ></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('di-zhi')" prop="country">
                        <!-- <el-input ></el-input> -->
                        <Area
                            :country.sync="form.country"
                            :continent.sync="form.continent"
                            :province.sync="form.province"
                            :city.sync="form.city"
                        />
                    </el-form-item>
                    <el-form-item :label="$t('suo-shu-hang-ye')" prop="industryId">
                        <Industry :ids.sync="form.industryId" :label.sync="form.customIndustry" />
                    </el-form-item>
                    <el-form-item :label="$t('gan-xing-qu-de-chan-pin')" prop="interestedProCateId">
                        <ProductCategory
                            v-model="form.interestedProCateId"
                            :label.sync="form.customCategoryId"
                            :backString="false"
                        />
                    </el-form-item>
                    <el-form-item :label="$t('jiao-liu-yu-yan')" prop="language">
                        <el-radio-group v-model="form.language">
                            <el-radio :key="index" :label="item[0]" v-for="(item, index) in languageModels">{{
                                $t(item[1])
                            }}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label-width="0" prop="sureRule" class="rule">
                        <el-checkbox v-model="form.sureRule">
                            <span>{{ $t('wo-tong-yi-ping-tai') }}</span>
                            &nbsp;
                            <el-link type="primary" @click.stop="getRule('RULE_REGISTER')">{{
                                $t('yong-hu-zhu-ce-xie-yi')
                            }}</el-link>
                            &nbsp; {{ $t('he') }}
                            <el-link type="primary" @click.stop="getRule('RULE_PRIVACY')">{{
                                $t('yin-si-zheng-ce')
                            }}</el-link>
                        </el-checkbox>
                    </el-form-item>
                    <el-form-item label-width="0">
                        <el-button
                            type="warning"
                            style="width: 330px; margin: auto; display: block"
                            :loading="loading"
                            @click="onSubmit"
                            >{{ $t('ti-jiao') }}</el-button
                        >
                    </el-form-item>
                </el-form>
            </el-card>
            <img class="logo" src="../../assets/zhuce_bg_logo.png" alt="" />
        </div>

        <el-dialog :title="$t(ruleTitle)" append-to-body :visible.sync="showRule" center width="900" top="5vh">
            <div class="rule-content scrollbar" :class="{ isEn: !isChinese }" ref="scrollbar" v-loading="loading">
                <div v-html="ruleInfo.enName" v-if="!isChinese"></div>
                <div v-if="!isChinese && ruleInfo.enName" class="rule-content-title">
                    {{ $i18n.messages.zh[ruleTitle] }}
                </div>
                <div v-html="ruleInfo.chName"></div>
            </div>
            <span slot="footer">
                <el-button @click="showRule = false" size="small">{{ $t('qu-xiao') }}</el-button>
                <el-button
                    type="warning"
                    @click="
                        showRule = false;
                        form.sureRule = true;
                    "
                    size="small"
                    >{{ $t('yue-du-bing-tong-yi') }}</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { generalEvent } from '../../utils/AipOcrClient';
import Area from '../../components/AreaInput';
import Phone from '../../components/PhoneInput';
import { delChild } from '../../utils/Array';
import { backPhone, regular, verifySms } from '../../utils/Sms';
import Cascader from '../../components/Cascader';
import formPage from '../../mixins/formPage';
import Industry from '../../components/select/Industry';
import ProductCategory from '../../components/select/ProductCategory';
export default {
    name: 'Register',
    mixins: [formPage],
    data() {
        return {
            form: {
                phone: '',
                code: '',
                password: '',
                password2: '',
                nickname: '',
                card: '',
                sex: '',
                email: '',
                position: '',
                interestedProCateId: [],
                language: 'ch',
                chCompanyName: '',
                sureRule: false,
                industryId: [],
                country: '',
                province: '',
                city: ''
            },
            rules: {
                phone: [
                    {
                        required: true,
                        trigger: 'blur',
                        validator: (rule, value, callback) => {
                            if (value) {
                                callback();
                            } else {
                                callback(new Error(this.$t('qing-shu-ru-shou-ji-hao-ma')));
                            }
                        }
                    },
                    {
                        validator: (rule, value, callback) => {
                            if (regular.test(value)) {
                                this.$http
                                    .post('/auth/isPhoneReg', {
                                        phone: backPhone(value)
                                    })
                                    .then(res => {
                                        if (res) {
                                            callback(new Error(this.$t('shou-ji-hao-yi-zhu-ce')));
                                        } else {
                                            callback();
                                        }
                                    });
                            } else {
                                callback(new Error(this.$t('shou-ji-hao-ge-shi-cuo-wu')));
                            }
                        },
                        trigger: 'blur'
                    }
                ],
                code: [
                    {
                        required: true,
                        validator: (rule, value, callback) => {
                            if (value) {
                                callback();
                            } else {
                                callback(this.$t('qing-shu-ru-duan-xin-yan-zheng-ma'));
                            }
                        },
                        trigger: 'blur'
                    }
                ],
                // country: [
                //     {
                //         required: true,
                //         trigger: 'blur',
                //         validator: (rule, value, callback) => {
                //             if (value) {
                //                 callback();
                //             } else {
                //                 callback(new Error(this.$t('di-zhi-bu-neng-wei-kong')));
                //             }
                //         }
                //     }
                // ],
                // industryId: [
                //     {
                //         required: true,
                //         trigger: 'blur',
                //         validator: (rule, value, callback) => {
                //             if (value.length > 0 || this.form.customIndustry) {
                //                 callback();
                //             } else {
                //                 callback(new Error(this.$t('suo-shu-hang-ye-bu-neng-wei-kong')));
                //             }
                //         }
                //     }
                // ],
                // interestedProCateId: [
                //     {
                //         required: true,
                //         trigger: 'blur',
                //         validator: (rule, value, callback) => {
                //             console.log(value);
                //             if (value.length > 0 || this.form.customIndustry) {
                //                 callback();
                //             } else {
                //                 callback(new Error(this.$t('gan-xing-qu-de-chan-pin-bu-neng-wei-kong')));
                //             }
                //         }
                //     }
                // ]
            },
            systemList: [
                { value: 'RULE_REGISTER', label: 'yong-hu-zhu-ce-xie-yi' },
                { value: 'RULE_PRIVACY', label: 'yin-si-zheng-ce' },
                { value: 'RULE_ENTER', label: '入驻协议' }
            ],
            showType: 'RULE_REGISTER',
            showRule: false,
            ruleText: '',
            ruleTitle: '',
            ruleInfo: { enName: '', chName: '' }
        };
    },
    computed: {
        ...mapState(['userInfo', 'showLogin'])
    },
    created() {
        this.$parent.$refs.login.loginVisible = false;
    },
    beforeDestroy() {
        if (this.showLogin) {
            this.$parent.$refs.login.loginVisible = true;
        }
    },
    methods: {
        submit() {
            if (!this.form.sureRule) {
                this.$message.warning(this.$t('qing-xian-yue-du-bing-tong-yi-xie-yi'));
                return;
            }
            var formData = { ...this.form };
            // formData.industryId = formData.industryId.join(',');
            delete formData.sureRule;
            delete formData.district;
            console.log(formData);
            formData.phone = backPhone(formData.phone);
            this.$http
                .post('/user/regUser', formData, { body: 'json' })
                .then(res => {
                    return this.$http.post(`/auth/loginAccount`, {
                        account: formData.phone,
                        password: this.form.password
                    });
                })
                .then(res => {
                    window.localStorage.setItem('imtPCtoken', res);
                    if (this.showLogin) {
                        this.$store.commit('updateShowLogin', false);
                    }
                    this.$message.success(this.$t('zhu-ce-cheng-gong'));

                    if (window.history.length > 0) {
                        history.back();
                    } else {
                        this.$router.replace('/home');
                    }
                })
                .catch(e => {
                    this.$message.warning(e.error);
                });
        },
        selectFilter(node, keyword) {
            console.log(node);
            console.log(keyword);
            return true;
        },
        getRule(name = 'RULE_REGISTER') {
            this.showType = name;
            this.showRule = true;
            this.$nextTick(() => {
                this.$refs.scrollbar.scrollTop = 0;
            });
            this.loading = true;

            this.ruleTitle = [...this.systemList].find(item => {
                return item.value === name;
            })?.label;
            this.$http.get(`/sysConfig/get/${name}`).then(res => {
                this.ruleInfo.chName = res.value;
                this.loading = false;
            });

            this.$http.get(`/sysConfig/get/${name}_EN`).then(res => {
                this.ruleInfo.enName = res.value;
                this.loading = false;
            });
        }
    },
    components: {
        Area,
        Cascader,
        Phone,
        Industry,
        ProductCategory
    }
};
</script>
<style lang="scss" scoped>
@import '../../styles/variables.scss';
.Register {
    max-width: $--page-center;
    margin: 0 auto;
    padding: $--page-padding;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
        width: 468/1920 * 100%;
        height: auto;
        margin-left: 169/1920 * 100%;
    }
}

.el-card {
    width: 880px;
    border-width: 0;
    color: $--color-text-regular;
    flex-shrink: 0;

    .title {
        font-size: 20px;
        font-weight: bold;
        color: #000000;
        line-height: 28px;
        text-align: center;
        padding: 12px 0px 5px;
    }
}

.el-form-item {
    margin-bottom: 24px;
}

.codeInput {
    display: flex;
    align-items: center;
    .el-button {
        padding: 12px 20px;
        min-width: 142px;
        box-sizing: border-box;
    }
}

.rule {
    margin-top: 50px;
    margin-bottom: 40px;
    .el-checkbox {
        color: #c0c4cc;
    }
}

.rule-content {
    height: 60vh;
    overflow: auto;
    min-height: 300px;
}

.isEn {
    .tips {
        font-size: 12px;
        color: #c0c4cc;
        line-height: 16px;
        padding: 10px 0;
    }
}
</style>
<style lang="scss">
@import '../../styles/variables.scss';
.Register {
    .el-input-group__prepend {
        width: 60px;
        background-color: transparent;
    }

    .code {
        .el-form-item__error {
            right: 160px;
        }
    }

    // .el-form-item__error {
    //     left: auto;
    //     top: 0px;
    //     right: 20px;
    //     transform: translateX(100%);
    //     white-space: nowrap;
    //     line-height: 40px;
    //     padding-top: 0;
    // }

    .el-cascader__tags {
        background-color: #fff;
        left: 1px;
    }

    .el-input-group--append .el-input__inner {
        // border-right-width: transparent;
    }

    .el-input__inner {
        box-sizing: border-box;
    }
    .el-input-group__append {
        background-color: transparent;
        overflow: hidden;
        border-width: 0;
        box-sizing: border-box;
        height: 40px;
        .el-button {
            background-color: transparentize($--color-warning, 0.9);
            color: $--color-warning;
            // box-sizing: border-box;
            padding: 0px 20px;
            line-height: 38px;
            height: 38px;
            display: block;
            border: 1px solid $--color-warning;
            &:hover {
                background-color: $--color-warning;
                color: $--color-white;
            }

            &.is-disabled {
                background-color: transparentize($--color-info, 0.9);
                color: $--color-info;
                box-shadow: inset 0 0 0 1px $--color-info;
            }
        }
    }

    .rule {
        .el-form-item__content {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .el-form-item__error {
            left: 50%;
            transform: translate(-50%, 20px);
            text-align: center;
            bottom: 0px;
        }
    }
}
</style>
