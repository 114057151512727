<template>
    <el-cascader
        v-model="area1"
        :props="{ expandTrigger: 'hover' }"
        :options="showList"
        filterable
        @input="getValue"
        :placeholder="$t('qing-xuan-ze-guo-jia-he-di-qu')"
    >
    </el-cascader>
</template>
<script>
import { mapState } from 'vuex';
import { getContinent } from '../utils/Area';
import { treeBackLabel } from '../utils/Array';
export default {
    name: 'Area',
    props: {
        value: {},
        valueType: {
            type: String,
            default: 'label'
        },
        country: {},
        province: {},
        city: {},
        district: {}, //区暂时删除
        continent: {} //州
    },
    data() {
        return { area1: [] };
    },
    computed: {
        ...mapState(['areaList']),
        backValue() {
            const values = [...this.area1];
            var continent = '',
                country = '',
                province = '',
                city = '';
            if (values.length > 0) {
                continent = values[0];
            }
            if (values.length > 1) {
                country = values[1];
            }
            if (values.length > 2) {
                province = values[2];
            }
            if (values.length > 3) {
                city = values[3];
            }

            return {
                continent,
                country,
                province,
                city
            };
        },
        showList() {
            return treeBackLabel([...this.areaList],(info)=>{
                return this.$t(info.label)
            });
        }
    },
    mounted() {
        this.getDefault();
    },
    methods: {
        getDefault() {
            console.log(this.country);
            // if (JSON.stringify(this.value) !== JSON.stringify(this.backValue) && this.value) {
            //     console.log(this.value.country);
            //     if (this.value.country === '中国') {
            //         this.area1 = [this.value.country, this.value.province, this.value.city];
            //     } else {
            //         this.area1 = ['海外', this.value.country, this.value.province];
            //     }
            // }
            const area = [];
            if (this.country && !this.continent) {
                console.log(getContinent(this.country).value + '2993883');
                area.push(getContinent(this.country).value);
                area.push(this.country);
            } else if (this.continent && this.country) {
                area.push(this.continent);
                area.push(this.country);
            }

            if (this.province) {
                area.push(this.province);
            }

            if (this.city && !['天津市', '北京市', '上海市', '重庆市'].includes(this.city)) {
                area.push(this.city);
            }

            this.area1 = area;
        },
        getValue() {
            this.$nextTick(() => {
                this.$emit('update:continent', this.backValue.continent);
                this.$emit('update:country', this.backValue.country);
                this.$emit('update:province', this.backValue.province);
                this.$emit('update:city', this.backValue.city || '');
                this.$emit('update:district', this.backValue.district || '');
            });
        },
        changeList(list) {
            if (!list) {
                return null;
            }
            return list.map(item => {
                return {
                    value: item.value,
                    label: this.$i18n.t(item.label),
                    children: this.changeList(item.children)
                };
            });
        }
    },
    watch: {
        country() {
            console.log('aaaaa');
            if (this.country && this.area1.length == 0) {
                this.getDefault();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.el-cascader {
    width: 100%;
}
</style>
